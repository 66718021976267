import React from "react"
import InfoBoxB from "../../components/infoBoxB/InfoBoxB"
import { replaceSpacing } from "../../utils/functions"
import { animationSlideRight } from "../../utils/scroll-animation"
import Hints from "../../common/hints/hints"

export default function MultipleCardFeatures(props) {
  const { features, styles } = props
  const isHeaderContent = features?.title || features?.hint > 0 ? true : false

  const renderTitle = () => {
    return (
      features?.title && (
        <h2 className={styles.title} {...animationSlideRight()}>
          {replaceSpacing(features?.title)}
        </h2>
      )
    )
  }

  return (
    <section
      id={features?.slug?.current}
      style={{
        backgroundColor: features?.backgroundColor
          ? features.backgroundColor.hex
          : "",
        paddingTop: isHeaderContent ? "" : "0px",
      }}
      className={styles.aboutContent}
    >
      <div className="default-container">
        <div className={styles.aboutContainer}>
          <div className={styles.titleWrapper}>
            {renderTitle()}
            <Hints
              hints={features?.hints}
              isAnimation={features?.isHintsAnimation}
              typingBackground={
                features?.backgroundColor
                  ? features.backgroundColor.hex
                  : "#422E88"
              }
            />
          </div>
          <div
            className={styles.contentWrapper}
            style={{ marginTop: isHeaderContent ? "" : "0px" }}
          >
            <div className={styles.features}>
              {features?.services_cards.map((cards, index) => (
                <div className={styles.featuresItem} key={index + "-services"}>
                  <InfoBoxB props={cards} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
