import React from "react"
import { replaceSpacing } from "../../utils/functions"
import { animationFade } from "../../utils/scroll-animation"
import * as styles from "./infoBox.module.scss"

export default function InfoBoxV2(props) {
  const feature = props.props

  const renderImage = () => {
    return (
      feature?.icon && (
        <div
          className={styles.imageContainer}
          style={{
            backgroundColor: feature?.backgroundColorIcon
              ? feature.backgroundColorIcon.hex
              : "",
          }}
        >
          <img className={styles.image} src={feature?.icon.asset.url} />
        </div>
      )
    )
  }

  const renderTitle = () => {
    return (
      feature?.title && (
        <h1 className={styles.featureTitle}>
          {replaceSpacing(feature?.title)}
        </h1>
      )
    )
  }

  const renderText = () => {
    return (
      feature?.info && (
        <p className={styles.text}>{replaceSpacing(feature?.info)}</p>
      )
    )
  }

  return (
    <div
      className={styles.feature}
      {...animationFade()}
      style={{
        backgroundColor: feature.backgroundColor
          ? feature.backgroundColor.hex
          : "",
      }}
    >
      <div className={styles.featureHeader}>
        {renderImage()}
        {renderTitle()}
      </div>

      {renderText()}
    </div>
  )
}
