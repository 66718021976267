// extracted by mini-css-extract-plugin
export var appSlider = "infoBox-module--app-slider--9ZrRk";
export var defaultContainer = "infoBox-module--default-container--2y3lq";
export var feature = "infoBox-module--feature--1x5zu";
export var featureHeader = "infoBox-module--feature-header--3sMz7";
export var featureSlider = "infoBox-module--feature-slider--12o-W";
export var featureTitle = "infoBox-module--feature-title--Z5d0Q";
export var image = "infoBox-module--image--1BDIG";
export var imageContainer = "infoBox-module--image-container--2_3LP";
export var innerPagesSlider = "infoBox-module--inner-pages-slider--urlNB";
export var leftAppSlide = "infoBox-module--left-app-slide--3qCRc";
export var logoSlider = "infoBox-module--logo-slider--ZCbNt";
export var phoneAppSelect = "infoBox-module--phone-app-select--Vf4DM";
export var phoneCaseSelect = "infoBox-module--phone-case-select--3iHPQ";
export var rightAppSlide = "infoBox-module--right-app-slide--XWkc2";
export var slickActive = "infoBox-module--slick-active--3tXHy";
export var slickArrow = "infoBox-module--slick-arrow--37VhR";
export var slickCenter = "infoBox-module--slick-center--1q7gf";
export var slickDots = "infoBox-module--slick-dots--Biutq";
export var slickNext = "infoBox-module--slick-next--1b7HY";
export var slickPrev = "infoBox-module--slick-prev--3FuQO";
export var slickSlide = "infoBox-module--slick-slide--1aSoz";
export var slickSlider = "infoBox-module--slick-slider--3SX2l";
export var slickTrack = "infoBox-module--slick-track--1Z0Gh";
export var solvingSlider = "infoBox-module--solving-slider--3382l";
export var text = "infoBox-module--text--1FEe4";
export var title = "infoBox-module--title--A9EB1";