import { graphql, useStaticQuery } from "gatsby"
import React, { useRef } from "react"
import InfoBox from "../../components/infoBox/InfoBox"
import { replaceSpacing } from "../../utils/functions"
import SliderActioner from "../../components/sliderActioner/sliderActioner"
import {
  animationSlideRight,
  animationFade,
} from "../../utils/scroll-animation"
import { useMediaQuery } from "react-responsive"
import Hints from "../../common/hints/hints"

export default function Features(props) {
  const { features, styles } = props
  const ref = useRef(null)
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 767 })
  const isHeaderContent = features?.title || features?.hint > 0 ? true : false
  const showNumber =
    features?.showNumber !== undefined ? features.showNumber : true

  var sliderSetting = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: isTablet ? 2 : isMobile ? 1 : 3,
  }

  const renderTitle = () => {
    return (
      features?.title && (
        <h2
          style={{
            color: features?.titleColor ? features.titleColor.hex : "",
          }}
          className={styles.title}
          {...animationSlideRight()}
        >
          {replaceSpacing(features?.title)}
        </h2>
      )
    )
  }

  return (
    <section
      id={features?.slug?.current}
      style={{
        backgroundColor: features?.backgroundColor
          ? features.backgroundColor.hex
          : "",
        paddingTop: isHeaderContent ? "" : "0px",
      }}
      className={styles.aboutContent}
    >
      <div className="default-container">
        <div className={styles.aboutContainer}>
          <div className={styles.titleWrapper}>
            {renderTitle()}
            <Hints
              hints={features?.hints}
              isAnimation={features?.isHintsAnimation}
              typingBackground={
                features?.backgroundColor
                  ? features.backgroundColor.hex
                  : "#271b4f"
              }
            />
          </div>
          <div
            className={styles.contentWrapper}
            style={{ marginTop: isHeaderContent ? "" : "0px" }}
          >
            {features?.slide ? (
              <div className={styles.features}>
                <SliderActioner
                  sliderClass="features-slider"
                  sliderSetting={sliderSetting}
                >
                  {features?.features.map((feature, index) => (
                    <div
                      className={styles.featuresItem}
                      ref={ref}
                      key={features?.id + index}
                    >
                      <div
                        style={{
                          color: features?.backgroundColor
                            ? features.backgroundColor.hex
                            : "",
                        }}
                        className={styles.numbers}
                      >
                        {index + 1 + "."}
                      </div>
                      <InfoBox
                        props={feature}
                        imageStyle={styles.infoBoxImage}
                      />
                    </div>
                  ))}
                </SliderActioner>
              </div>
            ) : (
              <div className={styles.features}>
                {features?.features.map((feature, index) => (
                  <div
                    className={styles.featuresItem}
                    ref={ref}
                    key={features?.id + index}
                  >
                    {!feature.isInfoBoxBackground && showNumber && (
                      <div
                        style={{
                          color: features?.backgroundColor
                            ? features.backgroundColor.hex
                            : "",
                        }}
                        className={styles.numbers}
                      >
                        {index + 1 + "."}
                      </div>
                    )}
                    <InfoBox props={feature} imageStyle={styles.infoBoxImage} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
