// extracted by mini-css-extract-plugin
export var appSlider = "infoBox-module--app-slider--2r8gz";
export var defaultContainer = "infoBox-module--default-container--3WsZb";
export var feature = "infoBox-module--feature--2tIem";
export var featureSlider = "infoBox-module--feature-slider--HjX1W";
export var image = "infoBox-module--image--HX5xT";
export var imageContainer = "infoBox-module--image-container--2YDXO";
export var innerPagesSlider = "infoBox-module--inner-pages-slider--ghuC5";
export var leftAppSlide = "infoBox-module--left-app-slide--3_CTc";
export var logoSlider = "infoBox-module--logo-slider--1yVJB";
export var phoneAppSelect = "infoBox-module--phone-app-select--TBSli";
export var phoneCaseSelect = "infoBox-module--phone-case-select---0hRb";
export var rightAppSlide = "infoBox-module--right-app-slide--23DQD";
export var slickActive = "infoBox-module--slick-active--3m-aO";
export var slickArrow = "infoBox-module--slick-arrow--2E3J7";
export var slickCenter = "infoBox-module--slick-center--M1bIU";
export var slickDots = "infoBox-module--slick-dots--38xir";
export var slickNext = "infoBox-module--slick-next--ESqAI";
export var slickPrev = "infoBox-module--slick-prev--2nqdf";
export var slickSlide = "infoBox-module--slick-slide--JwUwo";
export var slickSlider = "infoBox-module--slick-slider--12giv";
export var slickTrack = "infoBox-module--slick-track--3JL5T";
export var solvingSlider = "infoBox-module--solving-slider--3oWlA";
export var text = "infoBox-module--text--gRpq_";
export var title = "infoBox-module--title--24Bib";