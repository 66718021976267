import React from "react"
import { replaceSpacing } from "../../utils/functions"
import { animationFade } from "../../utils/scroll-animation"
import * as styles from "./infoBox.module.scss"

export default function InfoBox(props) {
  const feature = props.props
  const imageStyle = props.imageStyle

  const renderImage = () => {
    return (
      feature?.icon && (
        <div
          className={styles.imageContainer}
          style={{
            backgroundColor: feature?.backgroundColor
              ? feature.backgroundColor.hex
              : "",
          }}
        >
          <img
            className={imageStyle ? imageStyle : styles.image}
            src={feature?.icon.asset.url}
          />
        </div>
      )
    )
  }

  const renderTitle = () => {
    return (
      feature?.title && (
        <h1
          style={{
            color: feature?.titleColor ? feature.titleColor.hex : "",
          }}
          className={styles.title}
        >
          {replaceSpacing(feature?.title)}
        </h1>
      )
    )
  }

  const renderText = () => {
    return (
      feature?.info && (
        <p
          style={{
            color: feature?.textColor ? feature.textColor.hex : "",
          }}
          className={styles.text}
        >
          {replaceSpacing(feature?.info)}
        </p>
      )
    )
  }

  return (
    <div
      className={styles.feature}
      {...animationFade()}
      style={{
        backgroundColor: feature.isInfoBoxBackground
          ? feature.backgroundColor.hex
          : "",
        padding: feature.isInfoBoxBackground ? "30px" : "",
      }}
    >
      {renderImage()}
      {renderTitle()}
      {renderText()}
    </div>
  )
}
