// extracted by mini-css-extract-plugin
export var aboutContainer = "multiple_card_features-module--about-container--3paIP";
export var aboutContent = "multiple_card_features-module--about-content--1xGdS";
export var appSlider = "multiple_card_features-module--app-slider--MNfa_";
export var contentWrapper = "multiple_card_features-module--content-wrapper--2HPRx";
export var defaultContainer = "multiple_card_features-module--default-container--1S7N4";
export var featureSlider = "multiple_card_features-module--feature-slider--3DdR0";
export var features = "multiple_card_features-module--features--1tOL_";
export var featuresItem = "multiple_card_features-module--features-item--3q4OZ";
export var innerPagesSlider = "multiple_card_features-module--inner-pages-slider--1WQZr";
export var leftAppSlide = "multiple_card_features-module--left-app-slide--3mvVD";
export var logoSlider = "multiple_card_features-module--logo-slider--1UJp7";
export var phoneAppSelect = "multiple_card_features-module--phone-app-select--2I6sU";
export var phoneCaseSelect = "multiple_card_features-module--phone-case-select--1hUIq";
export var rightAppSlide = "multiple_card_features-module--right-app-slide--3N5jk";
export var slickActive = "multiple_card_features-module--slick-active--38G-C";
export var slickArrow = "multiple_card_features-module--slick-arrow--1MSvs";
export var slickCenter = "multiple_card_features-module--slick-center--2kONG";
export var slickDots = "multiple_card_features-module--slick-dots--15x8O";
export var slickNext = "multiple_card_features-module--slick-next--2HAgr";
export var slickPrev = "multiple_card_features-module--slick-prev--3z03v";
export var slickSlide = "multiple_card_features-module--slick-slide--109_8";
export var slickSlider = "multiple_card_features-module--slick-slider--3K6wF";
export var slickTrack = "multiple_card_features-module--slick-track--22jqw";
export var solvingSlider = "multiple_card_features-module--solving-slider--2M5bY";
export var title = "multiple_card_features-module--title--y3j1I";
export var titleWrapper = "multiple_card_features-module--title-wrapper--2QH5y";